<template lang="pug">
.showroom-view
  .showroom-view-text {{ $t('layout.header.contacts.showroom.text') }}
  //hours-available-component(:options="options")
  .showroom-view__appointment-wrapper
    .showroom-view__appointment-required(v-html="$t('layout.header.contacts.showroom.warning')")
    v-link.button.primary-color.light(:to="{ name: 'showroom' }") {{ $t('layout.header.contacts.showroom.button') }}
</template>

<script>
import { mapState } from 'vuex'
import HoursAvailableComponent from './hoursAvailable'
export default {
  name: 'ShowroomViewComponent',

  components: { HoursAvailableComponent },

  computed: {
    ...mapState({
      workingHours: (state) => state.app.workingHours
    }),

    options() {
      return this.workingHours.showRoom
    }
  }
}
</script>

<style lang="sass" scoped>
.showroom-view
  line-height: 20px
  &__appointment-required
    margin-bottom: 20px
</style>
